<template>
  <el-dialog
    v-model="dialogVisible"
    :width="800"
    class="teachingDialog"
    append-to-body
  >
    <div class="w-full h-full flex">
      <div class="h-full flex flex-col p-4 changeText w-[200px]">
        <el-steps
          :active="step"
          finish-status="success"
          process-status="finish"
          direction="vertical"
          class="w-auto mx-auto h-2/3 my-auto"
        >
          <el-step
            v-for="(item, index) in defaultTitleList"
            :key="item.title"
            :title="getTitle(index, item.title)"
            :class="index < step ? 'cursor-pointer' : ''"
            @click="onClickStep(index)"
          />
        </el-steps>
      </div>

      <div class="p-4 flex-1 flex flex-col">
        <div class="mb-4">
          <span class="font-bold text-assist1 text-xl">选择学科及年级</span>
        </div>
        <div class="flex-1 overflow-auto">
          <div
            class="grid gap-4  grid-cols-3 overflow-auto"
          >
            <el-button
              v-for="item in optionsList"
              :key="item.value"
              class="m-0 hover:text-primary hover:border-primary hover:bg-[#D3F8F6]"
              :class="item.value === chooseItem?.value ? 'text-primary border-primary bg-[#D3F8F6]' : 'border-none bg-[#F8F9FB] text-assist1'"
              @click="choose(item)"
            >
              {{ item.label }}
            </el-button>
          </div>
        </div>
        <div class="flex justify-center">
          <el-button
            class="gs_primary_btn_default mr-4"
            @click="closeDialog"
          >
            取消
          </el-button>
          <el-button
            class="gs_primary_btn_confirm"
            @click="nextStep"
          >
            {{ step === 3 ? '确认' : '下一步' }}
          </el-button>
        </div>
      </div>
    </div>
    <!-- <template
      v-if="step > 1"
      #footer
    >
      <el-button
        class="no_focus mr-[10px] ml-0 mb-[10px] shadow-2xl
          border-[#9D9FAC] text-assist1
          hover:bg-white hover:text-primary hover:border-primary
          active:bg-[#FFEED5] active:text-primary active:border-primary"
        @click="finish"
      >
        跳过
      </el-button>
    </template> -->
  </el-dialog>
</template>

<script lang="ts" setup>

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits<{(event: 'update:visible', val: boolean): void
}>()

const { getTextbookSelectList } = $enums
const user = $stores.user.useUserStore()

const step = ref(0) // 当前步骤
const tilteList = ref<string[]>([])
const valueList = ref<string[]>([])
const defaultTitleList = [
  { title: '学段', icon: 'phase_of_studying' },
  { title: '学科', icon: 'academic_discipline' },
  { title: '版本', icon: 'versions' },
  { title: '册别', icon: 'volume_number' }
]
const optionsList = computed(() => getTextbookSelectList(valueList.value))

// dialog是否显示
const dialogVisible = computed<boolean>({
  get: () => props.visible,
  set: (val) => emits('update:visible', val)
})
function closeDialog() {
  dialogVisible.value = false
}

watch(dialogVisible, (newVal) => {
  if (!newVal) {
    step.value = 0
    valueList.value = []
    tilteList.value = []
  }
})

const getTitle = (index: number, title: string) => tilteList.value[index] || title

const chooseItem = ref<any>()
const choose = (item: any) => {
  chooseItem.value = item
  // valueList[step.value] = item.value
  // tilteList[step.value] = item.label
  // if (step.value === 3) {
  //   finish()
  //   return
  // }
  // step.value += 1
}
function nextStep() {
  if (chooseItem.value) {
    valueList.value[step.value] = chooseItem.value.value
    tilteList.value[step.value] = chooseItem.value.label
    chooseItem.value = null
    if (step.value === 3) {
      finish()
      return
    }
    step.value += 1
  }
}

const { setTestPaperId } = $stores.testPaper.useTestPaperStore()
const finish = () => {
  const info = { valueList: valueList.value, tilteList: tilteList.value }
  $utils.setStorage('teachingMaterialInfo', info)
  user.$patch({
    teachingMaterialInfo: info
  })

  setTestPaperId(0) // 将试卷Id重置
  $utils.mitt.emit('chapterInfoReset') // 按教材部分信息重置
  $utils.mitt.emit('teachingMaterialUpdate') // 发布更新教材信息，促使试卷抽屉重新获取数据

  dialogVisible.value = false
}

function onClickStep(index: number) {
  if (index >= step.value) return

  tilteList.value.splice(index)
  valueList.value.splice(index)
  step.value = index
}
</script>

<style lang="scss" scoped>

:deep(.el-dialog__header) {
  padding: 0 !important;
}

:deep(.el-step__head) {
  display: flex;
}

:deep(.el-step__icon-inner) {
    display: none;
}

:deep(.el-step__head.is-finish), :deep(.el-step__title.is-finish), :deep(.el-step__title.is-wait) {
  color: theme('colors.assist1');
  .el-step__icon.is-text {
    border-color: #a7a7a9;
  }
  .el-step__icon-inner {
    display: none;
  }
}

:deep(.el-step__head.is-success), :deep(.el-step__title.is-success) {
  color: #27D086;
  .el-step__icon.is-text {
    border-color: #27D086;
    background-color: #D3F8F6;
  }
  .el-step__icon-inner {
    display: none;
  }
}

:deep(.el-step.is-simple .el-step__icon) {
  font-size: 24px;
}
:deep(.el-step.is-vertical .el-step__line) {
  border-left: 2px dashed #E9EAEC;
}

//:deep(.el-button:hover) {
//  color: theme('colors.primary');
//  border-color: theme('colors.primary');
//  background-color: #fcf3ea;
//}
</style>

<style lang="scss">
.teachingDialog {
  border-radius: 10px;
  height: 500px;
  overflow: hidden;
  .el-dialog__header {
    padding: 0;
    margin: 0;
    //background: url('~/assets/img/title_could.png') no-repeat;
    //background-size: cover;
    //border-radius: 10px 10px 0 0;
  }
  .el-dialog__body {
    height: 500px;
    padding: 0;
  }
}
.changeText {
  background-image: url("@/assets/img/exchange-textbook@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
