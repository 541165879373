<template>
  <div>
    <div
      class="flex items-center"
      :class="props.type === 'secondMenu' ? '' : 'px-5 py-4 justify-between'"
    >
      <span
        class="font-bold"
        :class="props.type === 'secondMenu' ? 'text-xl text-[#002039]' : 'text-base text-assist1'"
      >
        {{ bookName }}
      </span>
      <div
        @click="changeTextbook"
      >
        <slot
          name="changeBtn"
        >
          <el-button
            class="bg-[#14CDC4] text-white border-primary ml-4"
          >
            <SvgIcon
              class="text-xl"
              icon-class="switch-book"
            />
            <span class="ml-1">切换</span>
          </el-button>
        </slot>
      </div>
    </div>
    <!-- 选择教材dialog -->
    <client-only>
      <SelectTeachingMaterialDialog v-model:visible="dialogVisible" />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  type?: string,
}>(), {
  type: 'secondMenu'
})

const dialogVisible = ref(false)
const user = $stores.user.useUserStore()

onMounted(() => {
  const info = $utils.getStorage<TeachingMaterial>('teachingMaterialInfo')
  if (info) {
    user.$patch({
      teachingMaterialInfo: info
    })
  } else {
    dialogVisible.value = true
  }
})

// 显示教材的名称
const bookName = computed(() => {
  const { tilteList } = user.teachingMaterialInfo
  if (tilteList.length) {
    return tilteList.join('·')
  }
  return '选择教材'
})

// 切换教材回调
const changeTextbook = () => {
  dialogVisible.value = !dialogVisible.value
}
</script>

<style lang="scss" scoped>
.svg-icon {
  margin-right: 2px;
}
</style>
